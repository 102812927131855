/* Outer zone */
.dropzone {
  position: relative;
  display: block;
  width: 100%;
}

/* The outer div for group of cards */
.dropimage-group {
  /* Display using flexbox */
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap; /* If items exceed width, next line*/
}

.dropzone-card {
  width: 250px;
  height: 250px;
  margin: 20px;
  border-style: dashed;
  border-radius: 20px;
  border-color: grey;
  overflow: hidden;
}

/* The input zone is full-width as parent zone */
.dropinput {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transition: background-color 0.5s;
}

.dropinput-img {
  width: 50px;
  height: auto;
}

.dropinput .dropinput-button{
  position: relative;
  overflow: hidden;
}

.dropinput input[type='file'] {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
