/* Settings size & padding for cards */
.flipcard, .flipcard-flip {
  width: 250px;
  height: 250px;
  margin: 20px;
  position: relative;
}

/* Inner content */
.flipcard--inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform-style: preserve-3d;
}

/* When hover, rotate 180deg */
.flipcard-flip .flipcard--inner {
  transform: rotateY(180deg);
}

/* Settings for both back & front */
.flipcard--front,
.flipcard--back {
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 6px 14px 0px rgba(51, 54, 60, 0.06);
}

.flipcard--front{
  height: auto !important;
  transition: height 0.5s;
}

/* IMG wrapper settings */
.flipcard--img {
  overflow: hidden;
  display: block;
  width: 100%;
  max-height: 250px;
  background-color: rgb(243, 238, 238);
}

.flipcard--img img {
  display: block;
  background-position: center;
  max-width: 100%;
  max-height: 100%;
}

/* Front footer (Flip) */
.flipcard--footer {
  display: flex;
  justify-content: center; /* Horizontally Centering in flex*/
  align-items: center; /*Vertically centering in flex*/
  width: 100%;
  height: 50px;
  background-color: #ffffff;
}

.flipcard--footer p {
  margin: 0;
}

.flipcard--back {
  background-color: #355c7d;
  color: white;
  transform: rotateY(180deg);
}

.flipcard--description__title {
  outline: none;
  border: none;
  background-color: inherit;
  font-size: 20px;
  width: 100%;
  height: 30%;
  color: rgb(236, 192, 174);
  font-weight: bold;
  resize: none;
  margin-top: 20px;
  margin-left: 20px;
}

.flipcard--description__title::placeholder {
  font-weight: bold;
  color: rgb(204, 197, 197);
  font-style: italic;
}

.flipcard--description__title:focus {
  font-size: 20px;
  font-style: italic;
}

.flipcard--description__content {
  outline: none;
  border: none;
  background-color: inherit;
  width: 100%;
  height: 70%;
  color: white;
  font-weight: 500;
  resize: none;
  margin-left: 20px;
}

.flipcard--description__content::placeholder {
  font-weight: 500;
  color: rgb(204, 197, 197);
  font-style: italic;
}

.flipcard--groupbutton{
  position: absolute;
  left: 50%;
  top: 0%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
}

.flipcard--remove {
  z-index: 3;
  border-style: none;
  background-color: transparent;
}

.flipcard--remove > img {
  max-width: 30px;
  max-height: 30px;
}

.flipcard--flipback{
  z-index: 7;
  border-style: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.flipcard--flipback > img{
  max-width: 20px;
  max-height: 20px;
  display: block;
  margin: 0 0;
}
